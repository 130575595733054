import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// Components
import Page from "../../components/page/page";

const PageNotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <div className="page-not-found-with-nav-bar-container">
        <div className="page-not-found-with-nav-bar">
          <h1>Ne možemo da nađemo stranicu koju tražite.</h1>
          <p>Kod greške: 404</p>
          <p>Probajte neki od sledećih linkova:</p>
          <Link to="/" className="page-not-found-link">
            Naslovna
          </Link>
          <Link to="/login" className="page-not-found-link">
            Prijava
          </Link>
          <Link to="/signup" className="page-not-found-link">
            Registracija
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default PageNotFound;
