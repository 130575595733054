import React from "react";
import { useHistory } from "react-router";

// Styles
import "./footer.scss";

const Footer = () => {
  const history = useHistory();
  const getYear = () => {
    return " - © " + new Date().getFullYear() + ". Sva prava zadržana.";
  };

  const goToLink = () => {
    history.push("/");
  };

  return (
    <div className="footer-copyright container">
      <div className="footer-logo">
        <img
          alt="Portal TIP"
          width="40"
          height="40"
          src="../../../../../images/logo.png"
          onClick={goToLink}
        />
        <span className="name-next-to-logo">Portal TIP</span>
        <p>
          <strong>Portal TIP</strong>
          {getYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
