import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Components
import TextError from "../../../components/inputTextError/textError";

// Context
import { UserContext } from "../../../App";

// Constants
import {
  optionsLineType,
  optionsCableType,
  optionsSubstations,
} from "../../../common/consts";

// API
import {
  addPathAPI,
  updatePathByIdAPI,
  deletePathByIdAPI,
} from "../../../api/paths";

const PathModal = (props) => {
  const { show, onHide, onUpdate, onDelete, button, path } = props;
  const { user } = useContext(UserContext);
  const [formValues, setFormValues] = useState();

  const initialValues = {
    lineType: "cable",
    cableType: "PP41 3x35mm2",
    substation: "IP",
    powerLineNumber: "1",
  };

  const validationSchema = Yup.object({
    lineType: Yup.string().required("Ovo polje je obavezno"),
    cableType: Yup.string().required("Ovo polje je obavezno"),
    substation: Yup.string().required("Ovo polje je obavezno"),
    powerLineNumber: Yup.string().required("Ovo polje je obavezno"),
  });

  const deletePath = () => {
    deletePathByIdAPI(path._id).then((response) => {
      if (response.message) {
        toast.error(
          `Brisanje deonice kabla u baze nije uspelo zbog: ${response.message}`,
          {
            containerId: "Error",
          }
        );
      } else {
        onHide();
        onUpdate();
      }
    });
  };

  const onSubmit = (pathData, onSubmitProps) => {
    let data = {
      ...pathData,
      path: path,
      time: new Date(),
      addedBy: user.firstName + " " + user.lastName,
    };
    if (button === "Dodaj") {
      addPathAPI(data)
        .then(() => {
          onHide();
          onUpdate();
        })
        .catch((error) => {
          toast.error(
            `Dodavanje deonice dalekovoda nije uspelo zbog ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    } else if (button === "Izmeni") {
      updatePathByIdAPI(path._id, data)
        .then(() => {
          onHide();
          onUpdate();
        })
        .catch((error) => {
          toast.error(
            `Izmena podataka za deonicu dalekovoda nije uspela zbog ${error.response.data.message}`,
            {
              containerId: "Error",
            }
          );
          onSubmitProps.setSubmitting(false);
        });
    }
  };

  useEffect(() => {
    if (path && path.lineType) {
      setFormValues({
        lineType: path.lineType,
        cableType: path.cableType,
        substation: path.substation,
        powerLineNumber: path.powerLineNumber,
      });
    }
  }, [path]);

  return (
    <Modal
      show={show}
      onHide={button === "Dodaj" ? onDelete : onHide}
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Podaci o deonici DV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-form-path">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              return (
                <Form>
                  <div className="form-group">
                    <label htmlFor="lineType">Tip voda</label>
                    <Field
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      as="select"
                      id="lineType"
                      name="lineType"
                    >
                      {optionsLineType.map((lineType) => {
                        return (
                          <option key={lineType.value} value={lineType.value}>
                            {lineType.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="cableType">Tip kabla</label>
                    <Field
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      as="select"
                      id="cableType"
                      name="cableType"
                    >
                      {optionsCableType.map((cableType) => {
                        return (
                          <option key={cableType.value} value={cableType.value}>
                            {cableType.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="substation">Trafo stanica</label>
                    <Field
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      as="select"
                      id="substation"
                      name="substation"
                    >
                      {optionsSubstations.map((substation) => {
                        return (
                          <option
                            key={substation.value}
                            value={substation.value}
                          >
                            {substation.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="powerLineNumber">Dalekovod</label>
                    <Field
                      type="text"
                      disabled={
                        user &&
                        user.role !== "adminPowerSupply" &&
                        user.role !== "superAdmin"
                      }
                      id="powerLineNumber"
                      name="powerLineNumber"
                      className="form-input"
                    />
                    <ErrorMessage
                      name="powerLineNumber"
                      component={TextError}
                    />
                  </div>
                  <div className="modal-form-footer">
                    <button
                      disabled={formikProps.isSubmitting}
                      className="btn btn-info cancel"
                      onClick={button === "Dodaj" ? onDelete : onHide}
                    >
                      Izađi
                    </button>
                    {button === "Izmeni" && (
                      <button
                        type="button"
                        disabled={
                          formikProps.isSubmitting ||
                          (user &&
                            user.role !== "adminPowerSupply" &&
                            user.role !== "superAdmin")
                        }
                        className="btn btn-danger delete"
                        onClick={() => deletePath()}
                      >
                        Obriši
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={
                        !formikProps.isValid ||
                        formikProps.isSubmitting ||
                        (user &&
                          user.role !== "adminPowerSupply" &&
                          user.role !== "superAdmin")
                      }
                      className="btn btn-success save"
                    >
                      {button}{" "}
                      {formikProps.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PathModal;
