import React from "react";

const Legend = () => {
  return (
    <div className="container-legend-sticky-inner">
      <h3>Legenda</h3>
      <p>
        Nastavna kutija{" "}
        <span role="img" aria-label="nastavna kutija">
          <img src="/images/NK.ico" alt="nastavna kutija" />
        </span>
      </p>
      <p>
        Kablovski orman{" "}
        <span role="img" aria-label="kablovski orman">
          <img src="/images/KO.ico" alt="kablovski orman" />
        </span>
      </p>
      <p>
        Priključni orman{" "}
        <span role="img" aria-label="priključni orman">
          <img src="/images/PO.ico" alt="priključni orman" />
        </span>
      </p>
      <p>
        Razvodni orman{" "}
        <span role="img" aria-label="razvodni orman">
          <img src="/images/RO.ico" alt="razvodni orman" />
        </span>
      </p>
      <p>
        Razvodno postrojenje{" "}
        <span role="img" aria-label="razvodno postrojenje">
          <img src="/images/RP.ico" alt="razvodno postrojenje" />
        </span>
      </p>
      <p>
        Modularno razvodni orman{" "}
        <span role="img" aria-label="modularno razvodni orman">
          <img src="/images/MR.ico" alt="modularno razvodni orman" />
        </span>
      </p>
      <p>
        Trafo stanica 6/0.4kV{" "}
        <span role="img" aria-label="trafo stanica 6/0.4kV">
          <img src="/images/TS6_0.4.ico" alt="trafo stanica 6/0.4kV" />
        </span>
      </p>
      <p>
        Trafo stanica 35/6kV{" "}
        <span role="img" aria-label="trafo stanica 35/6kV">
          <img src="/images/TS35_6.ico" alt="trafo stanica 35/6kV" />
        </span>
      </p>
    </div>
  );
};

export default Legend;
