import React, { useContext } from "react";

// Components
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

// Context
import { UserContext } from "../../App";

const Page = (props) => {
  const { user } = useContext(UserContext);

  return (
    <React.Fragment>
      <div className="whole-page-body">
        {user && user.auth && <Navbar />}
        <div className="page-below-navigation">
          <div className="container">
            <div className="row">
              <div className="col-12 no-padding">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Page;
